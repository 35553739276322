.image-entry {
  position: relative;

  @include subgrid-container;

  @include tablet-landscape {
    align-items: center;
  }
}

.image-entry--reverse {
  @include tablet-portrait {
    grid-auto-flow: dense;
    direction: rtl;
  }
}

.image-entry--background {
  color: var(--color-white);
}

.main--campaigns .image-entry--background::before,
.main--rights .image-entry--background::before {
  content: '';
  position: absolute;
  top: var(--space-42);
  bottom: 0;
  left: calc(var(--margin) * -1);
  right: calc(var(--margin) * -1);
  z-index: -1;

  @include tablet-portrait {
    top: calc(var(--space-100) - var(--space-56));
  }

  @include desktop-large {
    left: calc((100vw - var(--max-width)) / 2 * -1);
    right: calc((100vw - var(--max-width)) / 2 * -1);
  }
}

.main--campaigns {
  .image-entry--background {
    color: var(--theme-v1-fg);

    &::before {
      background: var(--theme-v1-bg);
    }

    &.image-entry--alt {
      color: var(--theme-v2-fg);

      &::before {
        background: var(--theme-v2-bg);
      }
    }
  }
}

.main--rights .image-entry--background::before {
  background: var(--color-rights);
}

.image-entry__media,
.image-entry__content {
  grid-column: 1 / -1;
}

.image-entry__media {
  margin-bottom: var(--space-24);

  @include tablet-portrait {
    grid-column: span 6;
    width: 100%;
    height: 100%;
    margin-bottom: 0;

    .image-entry--background & {
      padding-bottom: var(--space-56);
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.image-entry__content {
  direction: ltr;

  .image-entry--background & {
    margin-bottom: var(--space-36);
  }

  @include tablet-portrait {
    grid-column: span 6;

    .image-entry--background & {
      margin: var(--space-100) 0 var(--space-56);
    }
  }

  @include tablet-landscape {
    grid-column: 8 / span 5;
  }
}

.image-entry__heading {
  margin-bottom: 1em;
  line-height: var(--lineheight-medium);

  @include text-20;

  @include tablet-portrait {
    @include text-30;
  }

  @include tablet-landscape {
    @include text-40;
  }
}

.image-entry__desc {
  margin-bottom: 2em;
  line-height: var(--lineheight-large);
}

.image-entry__cta {
  color: var(--color-red);

  .image-entry--background & {
    color: currentColor;
  }
}

.image-entry__background {
  position: absolute;
  top: var(--space-42);
  bottom: 0;
  left: calc(var(--margin) * -1);
  right: calc(var(--margin) * -1);
  z-index: -1;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @include tablet-portrait {
    top: calc(var(--space-100) - var(--space-56));
  }

  @include desktop-large {
    left: calc((100vw - var(--max-width)) / 2 * -1);
    right: calc((100vw - var(--max-width)) / 2 * -1);
  }
}
