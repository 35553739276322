.contact-slideout,
.contact-slideout__overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.contact-slideout {
  z-index: z('navigation');

  &.is-open {
    pointer-events: auto;
  }
}

.contact-slideout__overlay {
  background: var(--color-darkblue);
  opacity: 0;
  z-index: 1;
  transition: opacity 0.4s ease-in-out;

  .is-open & {
    opacity: 0.5;
    pointer-events: auto;
  }
}

.contact-slideout__slider {
  position: absolute;
  top: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  max-height: 100%;
  padding: 0 var(--margin) var(--margin);
  overflow-y: scroll;
  background: var(--color-gray);
  box-shadow: -0.9px 0px 0.9px -6px rgba(0, 0, 0, 0.07),
    -2.5px 0px 2.5px -6px rgba(0, 0, 0, 0.1),
    -6px 0px 6px -6px rgba(0, 0, 0, 0.13),
    -20px 0px 20px -6px rgba(0, 0, 0, 0.2);
  transform: translateX(calc(100% + 20px));
  transition: transform 0.4s ease-in-out;
  z-index: 2;

  .is-open & {
    transform: translateX(0);
  }

  @include tablet-portrait {
    width: 50vw;

    > * {
      max-width: 22.625rem;
    }
  }

  @include desktop {
    width: 40vw;
    padding: var(--space-72) var(--margin) var(--space-72) var(--space-56);
  }
}

.contact-slideout__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 3.5rem;
  margin-bottom: var(--space-24);

  @include desktop {
    height: unset;
    margin-bottom: var(--space-56);
  }
}

.contact-slideout__header__logo,
.contact-slideout__header__close {
  display: flex;
  align-items: center;
}

.contact-slideout__header__logo {
  width: 6.25rem;
  color: var(--color-darkblue);
}

.contact-slideout__header__close {
  width: 1.9375rem;
  height: 1.9375rem;
  color: var(--color-red);
  cursor: pointer;
}

.contact-slideout__form {
  color: var(--color-darkblue);
}

.contact-slideout__form__heading {
  margin-bottom: 0.75em;

  @include text-25;

  @include tablet-landscape {
    @include text-30;
  }
}

.contact-slideout__form__text,
.contact-slideout__form__note {
  line-height: var(--lineheight-large);
}

.contact-slideout__form__text {
  margin-bottom: 1.5em;
}

.contact-slideout__form__note {
  margin-top: 2.5em;
}

.contact-slideout__form__phone {
  display: flex;
  align-items: center;
  margin-bottom: var(--space-36);
  font-weight: var(--fontweight-extrabold);

  @include tablet-landscape {
    margin-bottom: var(--space-56);
  }
}

.contact-slideout__form__icon {
  display: inline-block;
  width: 0.875rem;
  height: 0.875rem;
  margin-right: 0.25rem;
  transform: translateY(0.025rem);
}

.contact-slideout__form__inputs button {
  background: var(--color-blue);
  border-radius: 9999px;

  &:hover {
    background: var(--color-blue);
  }
}
