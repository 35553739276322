html,
body {
  width: 100%;
  height: 100%;
}

body,
.main {
  display: flex;
  flex-direction: column;
  align-items: center;
}

body {
  overflow-x: hidden;

  @include font-body;
  @include text-15;

  @include desktop {
    @include text-16;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @include font-heading;
}

a {
  color: currentColor;
  font-weight: var(--fontweight-semibold);
  text-decoration: none;
}

img {
  display: block;
  width: 100%;
  height: auto;
}

svg {
  width: 100%;
  height: auto;
}

.input,
.button {
  @include font-body;
  @include text-15;
}

.input {
  display: block;
  padding: 0.35em 0.5em;
  background: var(--color-white);
  color: var(--color-darkblue);
  border: 1px solid currentColor;
  border-radius: 2px;

  &::placeholder {
    color: var(--color-darkblue);
    opacity: 0.5;
  }
}

.button {
  padding: 0.5em 1em;
  background: var(--color-darkblue);
  color: var(--color-white);
  border: none;
  border-radius: 9999px;
  text-transform: uppercase;
  font-weight: var(--fontweight-semibold);

  &.button--blue {
    background: var(--color-blue);
  }

  .main--campaigns & {
    background: var(--theme-primary);
    color: var(--theme-secondary);
  }
}

.link {
  span {
    display: inline-block;
    width: 2em;
    height: 1.2em;
    margin-left: 0.5em;
    transform: translateY(0.2em);
  }
}

.wysiwyg {
  line-height: var(--lineheight-large);

  h2,
  h3,
  h4 {
    margin-bottom: 0.5em;
  }

  h2 {
    font-size: 1.5em;
  }

  h3 {
    font-size: 1.125em;
  }

  h4 {
    @include font-body;
    font-weight: 600;
  }

  .subject-wrapper & h3,
  .subject-wrapper & h4 {
    color: var(--color-rights);
  }

  ol {
    list-style-type: decimal;
  }

  ul {
    list-style-type: disc;
  }

  ul,
  ol {
    padding-left: 1em;
    list-style-position: inside;

    ol {
      list-style-type: lower-latin;
      list-style-position: inside;
      margin-left: 1em;
    }

    ul {
      list-style-type: circle;
      list-style-position: inside;
      margin-left: 1em;
    }
  }
  li {
    margin-bottom: 0.5em;
  }

  p:not(:last-child),
  ul:not(:last-child),
  ol:not(:last-child) {
    margin-bottom: 1.5em;
  }

  strong {
    font-weight: 600;
  }

  em {
    font-style: italic;
  }
}

.caption {
  margin-top: 0.75em;
  letter-spacing: 0.025em;

  @include text-10;
}

.main {
  flex: 1 0 auto;
  width: 100%;
  margin-top: 3.5rem;

  @include desktop {
    margin-top: 6.25rem;
  }
}

.subject-wrapper--parent {
  @include grid-container;
}

.content {
  padding: var(--space-42) 0;

  @include tablet-portrait {
    padding: var(--space-56) 0;
  }

  @include tablet-landscape {
    padding: var(--space-72) 0;
  }

  @include desktop {
    padding: var(--space-100) 0;
  }

  .page-nav + & {
    margin-top: -50px;
  }
}

.subject-wrapper--parent .content {
  padding: var(--space-36) 0;

  @include tablet-portrait {
    padding: var(--space-42) 0;
  }

  @include tablet-landscape {
    padding: var(--space-56) 0;
  }

  @include desktop {
    padding: var(--space-72) 0;
  }
}
