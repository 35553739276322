.accordion {
  border-top: 1px solid var(--color-darkblue);
  border-bottom: 1px solid var(--color-darkblue);

  .content__block--accordion + .content__block--accordion & {
    border-top: none;
  }

  .content__block--columns + .content__block--accordion & {
    border-top: none;
  }
}

.accordion__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: var(--space-20);
  padding-top: var(--space-20);
  user-select: none;
  cursor: pointer;

  @include text-15;

  @include tablet-portrait {
    @include text-18;
  }
}

.accordion__header__title {
  line-height: var(--lineheight-medium);
}

.accordion__header__icon {
  width: 1.375rem;
  height: 1.375rem;
  transition: transform 0.3s ease-in-out;
  flex-shrink: 0;
  margin-left: 1.375rem;

  .is-open & {
    transform: rotate(90deg);
  }

  path[data-layer='vertical'] {
    transition: opacity 0.3s ease-in-out;
  }

  .is-open & path[data-layer='vertical'] {
    opacity: 0;
  }
}

.accordion__outer {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;

  .is-open & {
    max-height: none;
  }
}

.accordion__inner {
  padding-bottom: var(--space-20);
  padding-right: 2.75rem;
  line-height: var(--lineheight-large);
}
