.event {
  position: relative;
  color: var(--color-white);

  @include subgrid-container;

  &::before {
    content: '';
    position: absolute;
    top: var(--space-36);
    bottom: 0;
    left: calc(var(--margin) * -1);
    right: calc(var(--margin) * -1);
    background: var(--color-red);
    z-index: -1;

    @include tablet-portrait {
      top: 0;
    }

    @include desktop-large {
      left: calc((100vw - var(--max-width)) / 2 * -1);
      right: calc((100vw - var(--max-width)) / 2 * -1);
    }
  }
}

.event__media,
.event__content {
  grid-column: 1 / -1;
}

.event__media {
  position: relative;
  margin-bottom: var(--space-24);

  .event--reverse & {
    order: 2;
  }

  @include tablet-portrait {
    grid-column: span 6;
    margin-bottom: 0;
  }
}

.event__media-wrapper {
  @include tablet-portrait {
    position: absolute;
    top: 0;
    bottom: 0;

    .event--reverse & {
      left: 0;
      right: calc(var(--margin) * -1);
    }

    .event:not(.event--reverse) & {
      right: 0;
      left: calc(var(--margin) * -1);
    }

    img {
      width: 100%;
      height: 100%;
      object-position: center center;
      object-fit: cover;
    }
  }

  @include desktop-large {
    .event--reverse & {
      right: calc((100vw - var(--max-width)) / 2 * -1);
    }

    .event:not(.event--reverse) & {
      left: calc((100vw - var(--max-width)) / 2 * -1);
    }
  }
}

.event__content {
  margin-bottom: var(--space-36);

  .event--reverse & {
    order: 1;
  }

  @include tablet-portrait {
    grid-column: span 6;
    margin: var(--space-42) 0;
  }

  @include tablet-landscape {
    display: grid;
    grid-template-columns: 1fr 5fr 1fr;
    column-gap: var(--gutter);
    margin: var(--space-56) 0;

    .event--reverse & {
      > * {
        grid-column: 1 / -2;
      }
    }

    .event:not(.event--reverse) & {
      > * {
        grid-column: 2 / -1;
      }
    }
  }
}

.event__meta {
  @include text-16;

  display: flex;
  align-items: center;
  margin-bottom: 0.75em;
  line-height: 1;

  &::before {
    content: '';
    display: block;
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 0.5rem;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 22'%3E%3Cg fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' transform='translate(-2 -1)'%3E%3Crect width='18' height='18' data-name='Rectangle 29' rx='2' transform='translate(3 4)'/%3E%3Cpath d='M16 2v4' data-name='Line 17'/%3E%3Cpath d='M8 2v4' data-name='Line 18'/%3E%3Cpath d='M3 10h18' data-name='Line 19'/%3E%3C/g%3E%3C/svg%3E");
  }

  @include tablet-portrait {
    @include text-20;
  }

  @include desktop {
    @include text-25;
  }
}

.event__heading {
  @include text-20;

  margin-bottom: 0.5em;

  @include tablet-portrait {
    @include text-30;
  }

  @include desktop {
    @include text-40;
  }
}

.event__desc {
  @include text-15;

  margin-bottom: 2rem;
  font-weight: normal;
  text-wrap: balance;
  line-height: var(--lineheight-large);
}

.event__cta {
  padding-inline: 1.5em;
  padding-block: 0.75em;
  width: min-content;
  text-wrap: nowrap;
  font-weight: normal;
  background: var(--color-white);
  color: var(--color-red);
  border-radius: 9999px;
}
