.infobox {
  padding: var(--space-36) var(--space-24) 0;
  background: var(--theme-primary, var(--color-gold));
  color: var(--theme-secondary, var(--color-white));
  text-align: center;

  @include tablet-portrait {
    padding: var(--space-42) var(--space-72) 0;
  }
}

.infobox__heading {
  margin-bottom: 1em;

  @include text-15;

  @include tablet-portrait {
    @include text-20;
  }
}

.infobox__text {
  line-height: var(--lineheight-large);
}

.infobox__graphic {
  display: flex;
  max-width: 20rem;
  margin: 2.5rem auto 0;

  @include tablet-portrait {
    margin: 4.5rem auto 0;
  }
}

.infobox__link {
  margin-bottom: 2em;
}
