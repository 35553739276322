.header {
  position: fixed;
  top: 0;
  left: 0;
  height: 3.5rem;
  background: var(--theme-nav-bg, var(--color-white));
  color: var(--theme-nav-fg, var(--color-darkblue));
  box-shadow: 0px 0.7px 1.3px -6px rgba(0, 0, 0, 0.081),
    0px 2.2px 4.5px -6px rgba(0, 0, 0, 0.119),
    0px 10px 20px -6px rgba(0, 0, 0, 0.2);
  line-height: 1;
  white-space: nowrap;
  transform-origin: top center;
  z-index: z('header');

  @include fullwidth-container;
  @include text-10;

  @include tablet-landscape {
    @include text-15;
  }

  @include desktop {
    height: 6.25rem;
  }

  &.is-collapsed {
    @include desktop {
      transform: scaleY(0.56);

      .header__content {
        transform: scaleY(1.76);
      }
    }
  }
}

.header,
.header__content,
.header__contact,
.header__contact__content {
  transition: transform 0.2s ease-in-out;
}

.header--rights {
  background: var(--color-rights);
  color: var(--color-white);
}

.header__content {
  width: 100%;
  display: flex;
  padding-left: var(--margin);
  align-items: center;

  @include tablet-portrait {
    margin: 0 var(--margin);
    padding-left: 0;
  }

  @include desktop {
    @include flex-container;
  }
}

.header__logo {
  display: flex;
  align-items: center;
  width: 6.25rem;
  height: auto;
  margin-right: auto;
  color: var(--theme-nav-fg, var(--color-darkblue));
  transform-origin: left center;
  transition: transform 0.2s ease-in-out;

  &:visited {
    color: var(--theme-nav-fg, var(--color-darkblue));
  }

  .header--rights & {
    color: var(--color-white);
  }

  @include desktop {
    width: 10.5625rem;

    .is-collapsed & {
      transform: scale(0.8);
    }
  }
}

.header__logo--campaigns {
  flex: 1;
  color: currentColor;

  @include font-heading;
  @include text-15;

  svg {
    width: 1.625rem;
    margin-right: var(--space-10);
  }

  @include desktop {
    @include text-25;

    svg {
      width: 2.8125rem;
      margin-right: var(--space-15);
    }
  }
}

.header__navigation {
  display: none;

  @include desktop {
    display: block;
  }
}

.header__navigation--campaigns {
  @include tablet-portrait {
    display: block;
  }

  a {
    display: flex;
    align-items: center;
  }
}

.header__navigation__icon {
  display: flex;
  align-items: center;
  width: 1.25em;
  height: 1.25rem;
  margin-left: 0.5em;
}

.header__navigation__list,
.header__navigation__sublist {
  font-weight: 600;
}

.header__navigation__list {
  display: flex;
}

.header__navigation__sublist {
  position: absolute;
  flex-direction: column;
  top: 2em;
  left: calc(var(--space-24) * -1);
  padding: var(--space-15) var(--space-24) var(--space-15) 0;
  background: var(--theme-nav-bg, var(--color-white));
  color: var(--theme-nav-fg, var(--color-darkblue));
  opacity: 0;
  transform: translateY(-10px);
  pointer-events: none;
  transition: all 0.2s ease-in-out;

  .header__navigation__item:hover & {
    opacity: 1;
    pointer-events: auto;
    transform: translateY(0);
  }
}

.header__phone {
  display: none;

  @include tablet-portrait {
    display: block;
    color: var(--theme-nav-fg, var(--color-red));
  }
}

.header__navigation__item,
.header__phone {
  position: relative;
  margin-left: var(--space-24);

  .header__navigation__sublist & {
    @include text-12;
  }

  .header__navigation__sublist &:not(:last-child) {
    margin-bottom: var(--space-10);
  }
}

.header__navigation__item {
  line-height: 2em;
}

.header__menu {
  width: 1.375rem;
  height: 0.875rem;
  color: var(--theme-nav-fg, var(--color-darkblue));
  cursor: pointer;

  @include desktop {
    display: none;
  }
}

.header__phone__link {
  display: flex;
  align-items: center;
  font-weight: var(--fontweight-extrabold);
}

.header__phone__icon {
  display: inline-block;
  width: 0.875rem;
  height: 0.875rem;
  margin-right: 0.25rem;
  transform: translateY(0.025rem);
}

.header__contact {
  align-self: stretch;
  margin-left: var(--space-24);
  padding: 1.125rem;
  background: var(--theme-nav-fg, var(--color-gold));
  color: var(--theme-nav-bg, var(--color-white));
  font-weight: var(--fontweight-extrabold);
  text-transform: uppercase;
  cursor: pointer;

  .header--rights & {
    background: var(--color-white);
    color: var(--color-rights);
  }

  @include desktop {
    margin-left: var(--space-56);

    .is-collapsed & {
      transform: scaleY(0.56);
    }
  }
}

.header__contact__content {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  @include desktop {
    .is-collapsed & {
      transform: scaleY(1.76);
    }
  }
}

.header__contact__icon {
  display: flex;
  align-items: center;
  width: 1.25em;
  height: 1.25rem;
  margin-left: 0.5em;
}
