.page-nav,
.page-nav__list__items {
  background: var(--theme-secondary, var(--color-darkblue));
  color: var(--theme-primary, var(--color-white));
}

.page-nav {
  height: 50px;
  transform: translateY(-50%);
  box-shadow: 0px 0.7px 1.3px -6px rgba(0, 0, 0, 0.081),
    0px 2.2px 4.5px -6px rgba(0, 0, 0, 0.119),
    0px 10px 20px -6px rgba(0, 0, 0, 0.2);
  z-index: z('pagenavigation');

  @include flex-container;

  &.is-sticky {
    position: fixed;
    top: 81px;
    box-shadow: none;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: calc(var(--margin) * -1);
    right: calc(var(--margin) * -1);
    background: var(--theme-secondary, var(--color-darkblue));
    transition: transform 0.2s ease-out;
    transform: scaleX(0);
    box-shadow: 0px 0.7px 1.3px -6px rgba(0, 0, 0, 0.081),
      0px 2.2px 4.5px -6px rgba(0, 0, 0, 0.119),
      0px 10px 20px -6px rgba(0, 0, 0, 0.2);
    z-index: -1;

    @include desktop-large {
      left: calc((100vw - var(--max-width)) / 2 * -1);
      right: calc((100vw - var(--max-width)) / 2 * -1);
    }
  }

  &.is-sticky::before {
    transform: scaleX(1);
  }
}

.page-nav__selector,
.page-nav__list__items {
  padding: 0 2em;
}

.page-nav__selector {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: space-between;
  font-weight: 600;
  user-select: none;
  cursor: pointer;

  @include tablet-portrait {
    display: none;
  }
}

.page-nav__selector__icon {
  width: 1.25rem;
  height: 1.25rem;
  transform: rotate(90deg);
  transition: transform 0.3s ease-in-out;

  .is-open & {
    transform: rotate(270deg);
  }
}

.page-nav__list {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;

  .is-open & {
    max-height: none;
  }

  @include tablet-portrait {
    position: static;
    display: flex;
    max-height: none;

    @include text-12;
  }

  @include tablet-landscape {
    @include text-15;
  }
}

.page-nav__list__items {
  display: flex;
  flex-direction: column;
  padding: 1.5em 2em;

  @include tablet-portrait {
    position: static;
    width: max-content;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    padding: 0;
  }
}

.page-nav__list__item {
  white-space: nowrap;

  &:not(:last-child) {
    margin-bottom: 1.5em;
  }

  @include tablet-portrait {
    &:not(:last-child) {
      margin-right: 3em;
      margin-bottom: 0;
    }
  }

  @include desktop {
    &:not(:last-child) {
      margin-right: 4.5em;
    }
  }
}

.page-nav__link {
  display: block;
}
