.quote__icon {
  width: 1.5rem;
  margin-bottom: 1em;
  color: var(--theme-primary, var(--color-blue));

  @include tablet-portrait {
    width: 2.5rem;
    margin-bottom: 1.25em;
  }
}

.quote__text {
  margin-bottom: 1em;
  line-height: var(--lineheight-large);
  color: var(--color-green);

  @include font-heading;
  @include text-15;

  @include tablet-portrait {
    margin-bottom: 0.75em;

    @include text-20;
  }

  .main--campaigns & {
    color: var(--color-black);
  }
}

.quote__source {
  color: var(--color-blue);

  .main--campaigns & {
    color: var(--color-black);
  }

  @include tablet-portrait {
    text-align: right;
  }
}
