.intro-text {
  text-align: center;

  @include subgrid-container;

  &:not(:last-child) {
    margin-bottom: var(--space-56);
  }
}

.intro-text__heading,
.intro-text__desc {
  grid-column: 1 / -1;

  @include tablet-portrait {
    grid-column: 2 / -2;
  }
}

.intro-text__heading {
  margin-bottom: 0.5em;
  color: var(--color-rights);

  @include text-20;

  @include tablet-portrait {
    @include text-25;
  }

  @include tablet-landscape {
    @include text-30;
  }

  @include desktop {
    @include text-40;
  }
}

.intro-text__desc {
  line-height: var(--lineheight-large);
}
