.sponsors {
  @include grid-container;

  margin-bottom: var(--space-42);

  @include tablet-portrait {
    margin-bottom: var(--space-56);
  }

  @include tablet-landscape {
    margin-bottom: var(--space-72);
  }

  @include desktop {
    margin-bottom: var(--space-100);
  }
}

.sponsors__heading,
.sponsors__items {
  grid-column: 1 / -1;
}

.sponsors__heading {
  margin-bottom: 1em;
  text-align: center;

  @include text-20;

  @include tablet-portrait {
    @include text-30;
  }

  @include tablet-landscape {
    @include text-40;
  }
}

.sponsors__items {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @include tablet-portrait {
    flex-direction: row;
    flex-wrap: wrap;
  }
}

.sponsors__item {
  width: 100%;

  @include tablet-portrait {
    .sponsors__items--even & {
      max-width: 25%;
    }

    .sponsors__items--odd & {
      max-width: 33.3333%;
    }
  }
}
