:root {
  --gutter: 0.9375rem; /* 15px */
  --margin: 0.9375rem; /* 15px */
  --cols: 12;
  --max-width: 71.125rem; /* 1138px */

  --color-darkblue: #21334a;
  --color-red: #b72d3c;
  --color-gold: #a48659;
  --color-gold--hover: #8e744d;
  --color-gray: #f4f2f2;
  --color-darkgray: #707070;
  --color-green: #809e87;
  --color-blue: #266085;
  --color-rights: #b72d3c;
  --color-black: #000000;
  --color-white: #ffffff;

  --space-10: 0.625rem;
  --space-15: 0.9375rem;
  --space-20: 1.25rem;
  --space-24: 1.5rem;
  --space-36: 2.25rem;
  --space-42: 2.625rem;
  --space-56: 3.5rem;
  --space-72: 4.5rem;
  --space-100: 6.25rem;

  @include tablet-portrait {
    --gutter: 1.5625rem; /* 25px */
    --margin: 1.25rem; /* 24px */
  }

  @include tablet-landscape {
    --margin: 3.5rem; /* 56px */
  }

  @include desktop {
    --margin: 9.4375rem; /* 151px */
  }
}
