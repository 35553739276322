.hero {
  position: relative;

  @include fullwidth-container;
}

.hero:not(.hero--simple):not(.hero--event) {
  background: var(--theme-primary, var(--color-darkblue));
  color: var(--theme-secondary, var(--color-white));
}

.hero--campaigns {
  background: var(--theme-primary);
  color: var(--theme-secondary);

  .hero__inner {
    @include grid-container;
  }

  .hero__image {
    grid-column: 1 / -1;

    @include tablet-portrait {
      grid-column: span 6;
    }

    @include tablet-landscape {
      grid-column: span 7;
    }
  }

  .hero__image__wrapper {
    width: calc(100% + var(--margin) * 2);
    height: 100%;
    margin-left: calc(var(--margin) * -1);

    @include tablet-portrait {
      width: calc(100% + var(--margin));
      margin-left: 0;
    }

    @include desktop-large {
      width: calc(100% + ((100vw - var(--max-width)) / 2));
    }

    .image {
      width: 100%;
      min-height: 100%;
      object-fit: cover;
      object-position: left bottom;
    }
  }
}

.hero--event {
  background: var(--color-red);
  color: var(--color-white);
}

.hero__video {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.hero__content {
  padding-top: var(--space-36);
  z-index: 1;

  .hero--centered & {
    text-align: center;
  }

  @include tablet-portrait {
    padding-top: var(--space-56);
  }

  @include tablet-landscape {
    padding-top: var(--space-72);
  }

  @include grid-container;

  .hero--campaigns & {
    width: 100%;
    margin: 0;
    display: flex;
    align-items: center;
    grid-column: 1 / -1;

    @include tablet-portrait {
      grid-column: span 6;
    }

    @include tablet-landscape {
      grid-column: span 5;
    }
  }
}

.hero:not(.hero--simple) .hero__content {
  padding: var(--space-36) 0;

  @include tablet-portrait {
    padding: var(--space-56) 0;
  }

  @include desktop {
    padding: var(--space-72) 0;
  }
}

.hero__heading,
.hero__link,
.hero__meta {
  grid-column: 1 / -1;
  line-height: var(--lineheight-medium);

  @include tablet-portrait {
    grid-column: 2 / -2;
  }
}

.hero__meta {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  line-height: 1;

  span {
    text-transform: uppercase;
  }

  &::before {
    content: '';
    display: block;
    width: 1.125rem;
    height: 1.125rem;
    margin-right: 0.5rem;
    background-position: center;
    background-repeat: no-repeat;
  }

  .hero--news &::before {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 22'%3E%3Cg fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' transform='translate(-2 -1)'%3E%3Crect width='18' height='18' data-name='Rectangle 29' rx='2' transform='translate(3 4)'/%3E%3Cpath d='M16 2v4' data-name='Line 17'/%3E%3Cpath d='M8 2v4' data-name='Line 18'/%3E%3Cpath d='M3 10h18' data-name='Line 19'/%3E%3C/g%3E%3C/svg%3E");
  }

  .hero--nyhed &::before {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 22 22'%3E%3Cg fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' transform='translate(-1 -1)'%3E%3Ccircle cx='10' cy='10' r='10' data-name='Ellipse 11' transform='translate(2 2)'/%3E%3Cpath d='M12 16v-4' data-name='Line 15'/%3E%3Cpath d='M12 8h.01' data-name='Line 16'/%3E%3C/g%3E%3C/svg%3E");
  }

  .hero--hoeringssvar &::before {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 18 22'%3E%3Cg fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'%3E%3Cpath d='M11 1H3a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7Z' data-name='Path 1619'/%3E%3Cpath d='M11 1v6h6' data-name='Path 1620'/%3E%3Cpath d='M13 12H5' data-name='Line 13'/%3E%3Cpath d='M13 16H5' data-name='Line 14'/%3E%3Cpath d='M7 8H5' data-name='Path 1621'/%3E%3C/g%3E%3C/svg%3E");
  }
}

.hero__heading {
  .hero--simple & {
    color: var(--color-darkblue);
  }

  @include text-20;

  @include tablet-portrait {
    @include text-30;
  }

  @include desktop {
    @include text-40;
  }

  .hero--campaigns & {
    text-align: center;

    @include text-25;

    @include tablet-portrait {
      text-align: left;

      @include text-30;
    }

    @include tablet-landscape {
      @include text-40;
    }

    @include desktop {
      @include text-56;
    }
  }
}

.hero__link {
  margin-top: var(--space-36);
}

.hero:not(.hero--campaigns):has(.hero__image) {
  .hero__image {
    grid-column: span 5;
    grid-row: 1 / span 2;
    width: calc(100% + var(--margin));
    height: calc(100% + var(--space-36) * 2);
    margin-left: calc(var(--margin) * -1);
    margin-top: calc(var(--space-36) * -1);
    aspect-ratio: 1.5;

    .image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }

    @include tablet-portrait {
      height: calc(100% + var(--space-56) * 2);
      margin-top: calc(var(--space-56) * -1);
    }

    @include desktop {
      height: calc(100% + var(--space-72) * 2);
      margin-top: calc(var(--space-72) * -1);
    }

    @include desktop-large {
      height: 100%;
      margin-top: 0;
    }
  }

  .hero__heading,
  .hero__link,
  .hero__meta {
    grid-column: 7 / -2;
    align-content: center;
  }

  .hero__meta {
    align-self: end;
  }

  .hero__heading {
    align-self: start;
    text-wrap: balance;
  }
}
