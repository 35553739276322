.contacts {
  width: 100%;
  display: grid;
  margin: var(--space-20) 0;
  grid-template-columns: 1fr;
  column-gap: var(--gutter);
  row-gap: var(--space-42);

  @include tablet-portrait {
    grid-template-columns: 1fr 1fr;
    column-gap: var(--space-56);
    row-gap: var(--space-56);
  }

  @include tablet-landscape {
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: var(--space-72);
    row-gap: var(--space-72);
  }
}

.contacts__item {
  max-width: 75%;
  margin: 0 auto;
  text-align: center;

  @include tablet-landscape {
    max-width: 100%;
    margin: 0;
  }
}

.contacts__item__image {
  width: 100%;
  margin-bottom: var(--space-36);
}

.contacts__item__name {
  margin-bottom: var(--space-10);
}

.contacts__item__info {
  line-height: var(--lineheight-large);
}
