.campaigns {
  --duration: 0.6s;
  --easing: cubic-bezier(0.33, 1, 0.68, 1);

  width: 100%;
  position: relative;
  background: var(--background);
  color: var(--foreground);
  padding: var(--space-42) 0 var(--space-36);
  transition: transform var(--duration) var(--easing),
    background var(--duration) var(--easing),
    color var(--duration) var(--easing);

  @include fullwidth-container;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: calc(var(--margin) * -1);
    right: calc(var(--margin) * -1);
    background: var(--background);
    transition: background var(--duration) var(--easing);
    z-index: 0;
  }

  @include tablet-landscape {
    padding: var(--space-72) 0 var(--space-56);
  }

  @include desktop-large {
    &::before {
      left: calc((100vw - var(--max-width)) / 2 * -1);
      right: calc((100vw - var(--max-width)) / 2 * -1);
    }
  }
}

.campaigns__items-wrapper {
  width: 100vw;
  height: 100%;
  flex: 1 0 auto;
  padding: 0 var(--margin);
  display: flex;
  justify-content: center;
  overflow: hidden;

  @include desktop-large {
    padding: 0 calc((100vw - var(--max-width)) / 2);
  }
}

.campaigns__items {
  display: flex;
  width: 100%;
  transition-timing-function: var(--easing);
  transition-property: transform;

  .is-transitioning & {
    transition-duration: var(--duration);
  }
}

.campaigns__item {
  display: block;
  width: 100%;
  flex: 1 0 auto;
  align-items: center;
  opacity: 0;
  transform: translateX(0);
  transition-property: transform, opacity;
  transition-timing-function: var(--easing);

  @include subgrid-container;

  .is-transitioning & {
    transition-duration: var(--duration);
  }

  &.is-active {
    opacity: 1;
  }

  @include tablet-portrait {
    opacity: 1;
    transition-property: transform;

    &.is-prev {
      transform: translateX(calc(var(--gutter) + 8%));

      @include tablet-landscape {
        transform: translateX(calc(var(--gutter) + 6.25%));
      }
    }

    &.is-next {
      transform: translateX(calc((var(--gutter) + 8%) * -1));

      @include tablet-landscape {
        transform: translateX(calc((var(--gutter) + 6.25%) * -1));
      }
    }
  }

  @include tablet-portrait {
    opacity: 1;
  }
}

.campaigns__item__content {
  grid-column: 1 / -1;
  margin-bottom: 2.5rem;
  opacity: 1;
  transition: opacity var(--duration) var(--easing);

  .is-prev &,
  .is-next & {
    opacity: 0;
  }

  @include tablet-portrait {
    grid-column: 2 / span 4;
    margin-bottom: 0;
  }
}

.campaigns__item__media {
  grid-column: 1 / -1;
  margin: auto 0;
  transition-property: transform;
  transition-timing-function: var(--easing);

  .is-transitioning & {
    transition-duration: var(--duration);
  }

  @include tablet-portrait {
    grid-column: span 6;

    .is-next & {
      transform: translateX(-70%);
    }
  }
}

.campaigns__item__title {
  margin-bottom: 1.5em;
  text-transform: uppercase;

  @include font-body;
  @include text-10;

  span {
    font-weight: 600;
  }

  @include tablet-landscape {
    @include text-15;
  }
}

.campaigns__item__heading {
  margin-bottom: 0.5em;
  line-height: var(--lineheight-medium);

  @include text-20;

  @include tablet-landscape {
    @include text-30;
  }

  @include desktop {
    @include text-40;
  }
}

.campaigns__item__desc {
  margin-bottom: 2em;
  line-height: var(--lineheight-large);
  font-weight: normal;
}

.campaigns__indicators {
  position: absolute;
  bottom: 0;
  left: 50%;
  display: flex;
  transform: translate(-50%, calc((var(--space-56) / 2 * -1) + 175%));

  @include tablet-portrait {
    transform: translate(-50%, calc((var(--space-56) / 2 * -1) + 50%));
  }
}

.campaigns__indicators__dot {
  width: 0.625rem;
  height: 0.625rem;
  border-radius: 9999px;
  border: 1px solid var(--foreground);

  &:not(:first-child) {
    margin-left: 0.5rem;
  }

  &.is-active {
    background: var(--foreground);
  }
}

.campaigns__prev,
.campaigns__next {
  position: absolute;
  top: 0;
  height: 100%;
  width: var(--margin);

  @include desktop-large {
    width: calc((100vw - var(--max-width)) / 2);
  }
}

.campaigns__prev {
  left: 0;
  transform: translateX(-100%);
}

.campaigns__next {
  right: 0;
  transform: translateX(100%);
}

.campaigns__button {
  position: absolute;
  bottom: 0;
  width: 3.5rem;
  height: 3.5rem;
  padding: 1rem;
  transform: translateY(25%);
  background: var(--color-white);
  color: var(--color-black);
  border-radius: 9999px;
  box-shadow: 0.3px 0.3px 3.6px rgba(0, 0, 0, 0.052),
    0.8px 0.8px 10px rgba(0, 0, 0, 0.075),
    1.8px 1.8px 24.1px rgba(0, 0, 0, 0.098), 6px 6px 80px rgba(0, 0, 0, 0.15);
  cursor: pointer;

  @include tablet-portrait {
    width: 2.5rem;
    height: 2.5rem;
    padding: 0.75rem;
    top: 50%;
    bottom: unset;
    transform: translate(-50%, -50%);
  }
}

.campaigns__button--prev {
  left: var(--margin);

  svg {
    transform: rotate(180deg);
  }

  @include tablet-landscape {
    left: 0;
  }
}

.campaigns__button--next {
  right: var(--margin);

  @include tablet-portrait {
    transform: translate(50%, -50%);
  }

  @include tablet-landscape {
    right: 0;
  }
}
