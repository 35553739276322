.home-button,
.home-button__text,
.home-button__text a {
  display: flex;
}

.home-button,
.home-button__text a {
  align-items: center;
}

.home-button {
  width: calc(100% + var(--margin) * 2);
  margin-left: calc(var(--margin) * -1);
  padding: 1.25em var(--margin);
  background: var(--color-gray);

  @include text-12;
}

.home-button__logo {
  width: 1.825rem;
  height: 1.825rem;
  margin-right: 1em;
}

.home-button__text {
  flex-direction: column;

  span {
    margin-bottom: 0.25em;
  }

  svg {
    width: 1.125em;
    height: 1.125em;
  }
}
