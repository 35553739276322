.locations {
  position: relative;
  align-items: center;
  padding: var(--space-36) var(--space-24);
  color: var(--theme-v1-fg, var(--color-white));
  background-color: var(--color-blue);
  row-gap: 3.5rem;

  @include subgrid-container;

  @include tablet-portrait {
    padding: var(--space-56) 0;
    row-gap: 0;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: calc(var(--margin) * -1);
      right: calc(var(--margin) * -1);
      background-color: var(--color-blue);
      z-index: -1;
    }
  }

  @include tablet-landscape {
    padding: var(--space-72) 0;
  }

  @include desktop-large {
    &::before {
      left: calc((100vw - var(--max-width)) / 2 * -1);
      right: calc((100vw - var(--max-width)) / 2 * -1);
    }
  }
}

.locations::before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: calc(var(--margin) * -1);
  right: calc(var(--margin) * -1);
  background: var(--color-blue);
  z-index: -1;

  @include desktop-large {
    left: calc((100vw - var(--max-width)) / 2 * -1);
    right: calc((100vw - var(--max-width)) / 2 * -1);
  }
}

.locations__content {
  grid-column: 1 / -1;

  @include tablet-portrait {
    grid-column: 5 / -1;
    order: 2;
  }
}

.locations__heading {
  margin-bottom: 3rem;
  line-height: var(--lineheight-medium);

  @include text-20;

  @include tablet-portrait {
    @include text-30;
  }

  @include tablet-landscape {
    @include text-40;
  }
}

.locations__items {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 2rem;

  @include tablet-portrait {
    grid-template-columns: 1fr 1fr;
    column-gap: var(--gutter);
  }
}

.location {
  line-height: var(--lineheight-large);
  text-wrap: balance;
}

.location__title {
  text-transform: uppercase;
}

.location__link {
  display: block;
  margin-top: 1.5em;

  span {
    display: inline-block;
    width: 2em;
    height: 1.2em;
    margin-left: 0.5em;
    transform: translateY(0.2em);
  }
}

.locations__graphic {
  grid-column: 1 / -1;

  svg {
    width: 100%;
    height: auto;
  }

  @include tablet-portrait {
    grid-column: span 3;
    order: 1;
  }
}

.locations__logo {
  display: none;

  @include desktop {
    display: flex;
    position: absolute;
    top: var(--space-72);
    bottom: var(--space-72);
    right: calc(var(--margin) * -1);
    transform: translateX(50%);
    overflow: hidden;

    svg {
      height: 100%;
      max-height: 100%;
      width: auto;
      margin-left: auto;
    }
  }

  @include desktop-large {
    right: calc((100vw - var(--max-width)) / 2 * -1);
  }
}
