.password {
  margin: auto 0;

  @include grid-container;
}

.password__heading,
.password__form {
  grid-column: 2 / -2;
}

.password__heading {
  margin-bottom: var(--space-36);
  line-height: var(--lineheight-medium);
  text-align: center;

  @include text-25;

  @include tablet-portrait {
    @include text-30;
  }

  @include tablet-landscape {
    @include text-40;
  }
}

.password__form {
  display: flex;
  flex-direction: column;
  margin-bottom: var(--space-36);

  @include tablet-portrait {
    grid-column: 4 / -4;
  }

  @include desktop {
    grid-column: 5 / -5;
  }
}

.password__form__input {
  margin-bottom: 1rem;
}
