.content--page {
  @include grid-container;

  .subject-wrapper--parent & {
    grid-column: 1 / -1;

    @include tablet-landscape {
      width: 100%;
      grid-column: 4 / span 9;
      grid-template-columns: repeat(9, 1fr);
      margin: 0;
    }
  }

  .content__blocks {
    grid-column: 1 / -1;

    @include subgrid-container;

    @include tablet-landscape {
      .subject-wrapper--parent & {
        grid-template-columns: repeat(9, 1fr);
        grid-column: 1 / -1;
      }
    }
  }

  .content__block {
    grid-column: 1 / -1;
    width: 100%;

    &:not(:first-child) {
      margin-top: var(--space-24);
    }

    @include tablet-portrait {
      &.content__block--left {
        grid-column: 2 / span 5;
      }

      &.content__block--right {
        grid-column: 7 / span 5;
      }

      &:not(:first-child) {
        margin-top: var(--space-36);
      }
    }

    @include tablet-landscape {
      &:not(:first-child) {
        margin-top: var(--space-56);
      }
    }
  }

  .content__block--full:not(.content__block--banner):not(.content__block--form) {
    @include tablet-landscape {
      grid-column: 2 / -2;
    }
  }

  .subject-wrapper & .content__block--banner {
    grid-column: 2 / -2;
  }

  .content__block--columns {
    border-top: 1px solid var(--color-darkgray);
  }

  .content__block--accordion + .content__block--accordion,
  .content__block--columns + .content__block--columns,
  .content__block--columns + .content__block--accordion,
  .content__block--accordion + .content__block--columns {
    margin-top: 0;
    border-top: none;
  }
}
