.subject-navigation {
  display: none;

  @include tablet-landscape {
    display: block;
    width: 100%;
    height: 100%;
    position: relative;
    grid-column: span 3;
    padding-top: var(--space-36);
    padding-right: var(--gutter);
    background: var(--color-gray);

    &::before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: calc(var(--margin) * -1);
      right: calc(var(--gutter) * -1);
      background: var(--color-gray);
      z-index: -1;
    }
  }

  @include tablet-portrait {
    padding-top: var(--space-42);
  }

  @include tablet-landscape {
    padding-top: var(--space-56);
  }

  @include desktop {
    padding-top: var(--space-72);
  }

  @include desktop-large {
    &::before {
      left: calc((100vw - var(--max-width)) / 2 * -1);
    }
  }
}

.subject-navigation__list {
  position: sticky;
  top: calc(56px + var(--space-36));
}

.subject-navigation__item {
  margin-bottom: 1.5em;
  padding-bottom: 1.5em;
  color: var(--color-black);
  border-bottom: 1px solid currentColor;
  opacity: 0.5;

  &.is-active {
    color: var(--color-rights);
    opacity: 1;
  }
}

.subject-navigation__link {
  font-weight: normal;

  .is-active & {
    font-weight: 600;
  }
}
