.columns {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid var(--color-darkgray);

  @include tablet-landscape {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

.columns__item {
  flex: 1;
  min-width: 100%;
  padding: var(--space-56) var(--gutter) 0 0;

  &:last-child {
    padding-bottom: var(--space-56);
  }

  @include tablet-landscape {
    min-width: calc(50% - var(--gutter) / 2);
  }

  @include desktop {
    min-width: calc(33.33333% - (var(--gutter) * 2 / 3));

    &:not(:last-child) {
      padding-bottom: var(--space-56);
    }
  }
}

.columns__item__icon {
  margin-bottom: 1.25rem;
  color: var(--theme-icons, var(--color-blue));

  &.columns__item__icon--references {
    width: 2.6875rem;
  }

  &.columns__item__icon--docs {
    width: 2.1875rem;
  }
}

.columns__item__heading,
.columns__item__text {
  max-width: 56ch;
}

.columns__item__heading {
  margin-bottom: 0.5em;
  color: var(--color-darkblue);

  @include text-15;

  @include tablet-portrait {
    @include text-20;
  }
}

.columns__item__text {
  margin-bottom: 1.5em;
  line-height: var(--lineheight-large);
}

.columns__item__link {
  color: currentColor;
}
