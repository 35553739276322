@mixin base-layout {
  width: calc(100% - 2 * var(--margin));
  max-width: var(--max-width);
  margin-left: var(--margin);
  margin-right: var(--margin);
}

@mixin grid-container {
  display: grid;
  grid-template-columns: repeat(var(--cols), 1fr);
  column-gap: var(--gutter);

  @include base-layout;
}

@mixin subgrid-container {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(var(--cols), 1fr);
  column-gap: var(--gutter);
}

@mixin flex-container {
  display: flex;

  @include base-layout;
}

@mixin fullwidth-container {
  width: 100%;
  display: flex;
  justify-content: center;
}
