.footer {
  position: relative;
  padding: var(--space-56) 0;
  background: var(--theme-nav-bg, var(--color-darkblue));
  color: var(--theme-nav-fg, var(--color-white));

  &.footer--rights {
    background: var(--color-rights);
  }

  @include fullwidth-container;

  a {
    color: currentColor;
    font-weight: var(--fontweight-regular);
  }
}

.footer__content {
  row-gap: var(--space-56);

  @include grid-container;
}

.footer__block {
  grid-column: 1 / -1;

  @include tablet-portrait {
    grid-column: span 6;
  }

  @include desktop {
    grid-column: span 3;
  }
}

.footer__block__heading {
  margin-bottom: 1.5em;

  @include font-body;

  font-weight: var(--fontweight-semibold);
}

.footer__block__content {
  line-height: var(--lineheight-large);

  @include text-10;

  &.footer__block__content--social {
    display: flex;
  }
}

.footer__block__icon {
  width: 1.25rem;
  height: 1.25rem;

  &:not(:last-child) {
    margin-right: var(--space-20);
  }
}

.footer__graphic {
  display: none;

  @include tablet-landscape {
    display: flex;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    overflow: hidden;

    svg {
      height: 100%;
      max-height: 100%;
      width: auto;
      margin-left: auto;
    }
  }
}
