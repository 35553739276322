.news-intro {
  @include fullwidth-container;

  margin-bottom: var(--space-24);
  background: var(--color-gray);

  @include tablet-portrait {
    margin-bottom: var(--space-36);
  }

  @include desktop {
    margin-bottom: var(--space-56);
  }
}

.news-intro__content {
  @include grid-container;

  padding-block: var(--space-36);

  @include tablet-portrait {
    padding-block: var(--space-56);
  }

  @include desktop {
    padding-block: var(--space-72);
  }
}

.news-intro__title,
.news-intro__text {
  grid-column: 1 / -1;

  @include tablet-portrait {
    grid-column: 2 / -2;
  }
}

.news-intro__title {
  @include text-20;

  margin-bottom: 1em;
  line-height: var(--lineheight-medium);

  @include tablet-portrait {
    @include text-30;
  }

  @include desktop {
    @include text-40;
  }
}

.news-intro__text {
  @include text-15;

  line-height: var(--lineheight-large);
}
