@mixin font-heading {
  font-family: 'Jakarta Sans', sans-serif;
  font-weight: 800;
  font-style: normal;
}

@mixin font-body {
  font-family: 'Inter', sans-serif;
  font-weight: normal;
  font-style: normal;
}

@mixin text-10 {
  font-size: 0.625rem;
}

@mixin text-12 {
  font-size: 0.75rem;
}

@mixin text-15 {
  font-size: 0.9375rem;
}

@mixin text-16 {
  font-size: 1rem;
}

@mixin text-18 {
  font-size: 1.125rem;
}

@mixin text-20 {
  font-size: 1.25rem;
}

@mixin text-25 {
  font-size: 1.5625rem;
}

@mixin text-30 {
  font-size: 1.875rem;
}

@mixin text-40 {
  font-size: 2.5rem;
}

@mixin text-56 {
  font-size: 3.5rem;
}

:root {
  --lineheight-small: 1;
  --lineheight-medium: 1.2;
  --lineheight-large: 1.5;
  --fontweight-regular: normal;
  --fontweight-medium: 500;
  --fontweight-semibold: 600;
  --fontweight-bold: bold;
  --fontweight-extrabold: 800;
}
