.image-carousel {
  position: relative;
  width: 100%;
}

.image-carousel__items {
  position: relative;
  height: 0;
  transition: padding 0.2s ease-in-out;
}

.image-carousel__item {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.4s ease-in-out;

  .caption {
    text-align: center;
  }

  &.is-active {
    opacity: 1;
    z-index: 2;
  }
}

.image-carousel__indicators {
  position: absolute;
  bottom: 0;
  left: 50%;
  display: flex;
  transform: translate(-50%, calc((var(--space-56) / 2 * -1) + 175%));
  z-index: 3;

  @include tablet-portrait {
    transform: translate(-50%, calc((var(--space-56) / 2 * -1) + 50%));
  }
}

.image-carousel__indicators__dot {
  width: 0.625rem;
  height: 0.625rem;
  border-radius: 9999px;
  border: 1px solid var(--color-white);

  &:not(:first-child) {
    margin-left: 0.5rem;
  }

  &.is-active {
    background: var(--color-white);
  }
}

.image-carousel__button {
  position: absolute;
  top: 50%;
  width: 3.5rem;
  height: 3.5rem;
  padding: 1rem;
  background: var(--color-white);
  color: var(--color-black);
  border-radius: 9999px;
  box-shadow: 0.3px 0.3px 3.6px rgba(0, 0, 0, 0.052),
    0.8px 0.8px 10px rgba(0, 0, 0, 0.075),
    1.8px 1.8px 24.1px rgba(0, 0, 0, 0.098), 6px 6px 80px rgba(0, 0, 0, 0.15);
  cursor: pointer;
  z-index: 3;

  @include tablet-portrait {
    width: 2.5rem;
    height: 2.5rem;
    padding: 0.75rem;
  }
}

.image-carousel__button--prev {
  left: var(--margin);
  transform: translate(-50%, -50%);

  svg {
    transform: rotate(180deg);
  }

  @include tablet-portrait {
    left: 0;
  }
}

.image-carousel__button--next {
  right: var(--margin);
  transform: translate(50%, -50%);

  @include tablet-portrait {
    right: 0;
  }
}
