.subject-header__subject {
  display: flex;
  align-items: center;
  color: var(--color-rights);
}

.subject-header__subject__icon {
  width: 1.25em;
  margin-right: 0.35em;
}

.subject-header__breadcrumbs {
  display: flex;

  @include text-10;

  @include tablet-landscape {
    @include text-15;
  }
}

.subject-header__title {
  color: var(--color-rights);

  @include text-25;

  @include tablet-portrait {
    @include text-30;
  }

  @include tablet-landscape {
    @include text-40;
  }

  @include desktop {
    @include text-56;
  }
}

.subject-header__breadcrumbs__link,
.subject-header__breadcrumbs__span {
  font-weight: normal;
}

.subject-header__breadcrumbs__link {
  opacity: 0.5;

  &::before {
    content: '<--';
    padding-right: 0.75em;
  }

  .subject-header__breadcrumbs__item:not(:first-child) &::before {
    padding-left: 0.75em;
  }
}

.subject-header__breadcrumbs__span {
  color: var(--color-rights);

  &::before {
    content: '·';
    padding: 0 0.75em;
    color: var(--color-black);
    opacity: 0.5;
  }
}

/*
* Subject parent header
*/

.subject-wrapper--parent .subject-header {
  grid-column: 1 / -1;
  position: relative;
  padding: var(--space-36) var(--margin);
  background: var(--color-gray);
  z-index: 1;

  @include subgrid-container;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: calc(var(--margin) * -1);
    right: calc(var(--margin) * -1);
    background: var(--color-gray);
    z-index: -1;

    @include desktop-large {
      left: calc((100vw - var(--max-width)) / 2 * -1);
      right: calc((100vw - var(--max-width)) / 2 * -1);
    }
  }

  @include tablet-portrait {
    padding: var(--space-42) var(--margin);
  }

  @include tablet-landscape {
    padding: var(--space-56) 0 var(--space-24);
  }

  @include desktop {
    padding: var(--space-72) 0 var(--space-15);
  }

  .subject-header__subject,
  .subject-header__description,
  .subject-header__breadcrumbs {
    grid-column: 1 / -1;
  }

  .subject-header__subject {
    margin-bottom: 0.5em;

    @include text-25;

    @include tablet-portrait {
      @include text-30;
    }

    @include tablet-landscape {
      order: 2;
      grid-column: 5 / span 8;
      margin-bottom: 0;

      @include text-40;
    }

    @include desktop {
      @include text-56;
    }
  }

  .subject-header__subject__icon {
    width: 1.25em;
    margin-right: 0.35em;
  }

  .subject-header__description {
    margin-bottom: 1.5em;
    max-width: 64ch;
    color: var(--color-rights);
    line-height: var(--lineheight-large);

    @include tablet-landscape {
      order: 1;
      grid-column: span 3;
      margin-bottom: 0;
    }
  }

  .subject-header__breadcrumbs {
    grid-column: 1 / -1;

    @include tablet-landscape {
      order: 3;
      margin-top: var(--space-56);
    }
  }
}

/*
 * Subject subpage header
 */

.subject-wrapper:not(.subject-wrapper--parent) .subject-header {
  padding-top: var(--space-36);

  @include grid-container;

  .subject-header__subject,
  .subject-header__breadcrumbs,
  .subject-header__title {
    grid-column: 1 / -1;

    @include tablet-landscape {
      grid-column: 2 / -2;
    }
  }

  .subject-header__subject {
    @include text-15;

    @include tablet-landscape {
      @include text-20;
    }
  }

  .subject-header__breadcrumbs {
    margin-top: var(--space-10);
    margin-bottom: var(--space-36);

    @include tablet-portrait {
      margin-top: var(--space-15);
      margin-bottom: var(--space-42);
    }

    @include tablet-landscape {
      margin-top: var(--space-24);
      margin-bottom: var(--space-56);
    }

    @include desktop {
      margin-top: var(--space-36);
      margin-bottom: var(--space-72);
    }
  }

  @include tablet-portrait {
    padding-top: var(--space-42);
  }

  @include tablet-landscape {
    padding-top: var(--space-56);
  }

  @include desktop {
    padding-top: var(--space-72);
  }
}
