@font-face {
  font-family: 'Jakarta Sans';
  src: url('../../src/assets/fonts/PlusJakartaSans-ExtraBold.woff2')
    format('woff2'),
    url('../../src/assets/fonts/PlusJakartaSans-ExtraBold.woff') format('woff');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('../../src/assets/fonts/Inter-Regular.woff2') format('woff2'),
    url('../../src/assets/fonts/Inter-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('../../src/assets/fonts/Inter-Italic.woff2') format('woff2'),
    url('../../src/assets/fonts/Inter-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('../../src/assets/fonts/Inter-Medium.woff2') format('woff2'),
    url('../../src/assets/fonts/Inter-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('../../src/assets/fonts/Inter-MediumItalic.woff2') format('woff2'),
    url('../../src/assets/fonts/Inter-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('../../src/assets/fonts/Inter-SemiBold.woff2') format('woff2'),
    url('../../src/assets/fonts/Inter-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('../../src/assets/fonts/Inter-SemiBoldItalic.woff2') format('woff2'),
    url('../../src/assets/fonts/Inter-SemiBoldItalic.woff') format('woff');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('../../src/assets/fonts/Inter-Bold.woff2') format('woff2'),
    url('../../src/assets/fonts/Inter-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('../../src/assets/fonts/Inter-BoldItalic.woff2') format('woff2'),
    url('../../src/assets/fonts/Inter-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('../../src/assets/fonts/Inter-ExtraBold.woff2') format('woff2'),
    url('../../src/assets/fonts/Inter-ExtraBold.woff') format('woff');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('../../src/assets/fonts/Inter-ExtraBoldItalic.woff2') format('woff2'),
    url('../../src/assets/fonts/Inter-ExtraBoldItalic.woff') format('woff');
  font-weight: 800;
  font-style: italic;
  font-display: swap;
}
