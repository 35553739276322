.news-list {
  @include grid-container;
}

.news-list__categories,
.news-list__items {
  grid-column: 1 / -1;

  @include tablet-portrait {
    grid-column: 2 / -2;
  }
}

.news-list__categories {
  margin-bottom: var(--space-36);
  display: flex;
  gap: 0.5rem;

  @include tablet-portrait {
    margin-bottom: var(--space-56);
  }
}

.news-list__category:not(:last-child)::after {
  content: '·';
  margin-left: 0.25rem;
}

.news-list__category__link {
  text-transform: uppercase;
  font-weight: normal;

  &.is-active {
    border-bottom: 1px solid currentColor;
  }
}

.news-list__item {
  margin-bottom: var(--space-36);
  padding-bottom: var(--space-36);

  &:not(:last-child) {
    border-bottom: 1px solid var(--color-black);
  }

  @include tablet-portrait {
    margin-bottom: var(--space-56);
    padding-bottom: var(--space-56);
  }

  @include desktop {
    margin-bottom: var(--space-72);
    padding-bottom: var(--space-72);
  }
}

.news-list__item__meta {
  display: flex;
  align-items: center;
  margin-bottom: 0.5em;
  text-transform: uppercase;

  &::before {
    content: '';
    display: block;
    width: 1.125rem;
    height: 1.125rem;
    margin-right: 0.5rem;
    background-position: center;
    background-repeat: no-repeat;
  }

  .news-list__item--nyhed &::before {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 22 22'%3E%3Cg fill='none' stroke='%23000' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' transform='translate(-1 -1)'%3E%3Ccircle cx='10' cy='10' r='10' data-name='Ellipse 11' transform='translate(2 2)'/%3E%3Cpath d='M12 16v-4' data-name='Line 15'/%3E%3Cpath d='M12 8h.01' data-name='Line 16'/%3E%3C/g%3E%3C/svg%3E");
  }

  .news-list__item--event &::before {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 22'%3E%3Cg fill='none' stroke='%23000' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' transform='translate(-2 -1)'%3E%3Crect width='18' height='18' data-name='Rectangle 29' rx='2' transform='translate(3 4)'/%3E%3Cpath d='M16 2v4' data-name='Line 17'/%3E%3Cpath d='M8 2v4' data-name='Line 18'/%3E%3Cpath d='M3 10h18' data-name='Line 19'/%3E%3C/g%3E%3C/svg%3E");
  }

  .news-list__item--hoeringssvar &::before {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 18 22'%3E%3Cg fill='none' stroke='%23000' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'%3E%3Cpath d='M11 1H3a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7Z' data-name='Path 1619'/%3E%3Cpath d='M11 1v6h6' data-name='Path 1620'/%3E%3Cpath d='M13 12H5' data-name='Line 13'/%3E%3Cpath d='M13 16H5' data-name='Line 14'/%3E%3Cpath d='M7 8H5' data-name='Path 1621'/%3E%3C/g%3E%3C/svg%3E");
  }
}

.news-list__item__title {
  @include text-18;

  display: inline-block;
  margin-bottom: 1.5em;
  line-height: var(--lineheight-medium);

  @include tablet-portrait {
    @include text-20;
  }
}

.news-list__item__teaser {
  @include text-15;

  margin-bottom: 2em;
  line-height: var(--lineheight-large);
}

.news-list__item__cta {
  display: block;
  margin-top: 1.5em;

  span {
    display: inline-block;
    width: 2em;
    height: 1.2em;
    margin-left: 0.5em;
    transform: translateY(0.2em);
  }
}
