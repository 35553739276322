.banner {
  position: relative;
  padding: var(--space-36) var(--space-24) 0;
  color: var(--theme-v1-fg, var(--color-white));
  background-color: var(--theme-v1-bg, var(--color-green));

  @include subgrid-container;

  &.banner--alt {
    color: var(--theme-v2-fg, var(--color-white));
    background-color: var(--theme-v2-bg, var(--color-green));
  }

  @include tablet-portrait {
    padding: var(--space-56) 0;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: calc(var(--margin) * -1);
      right: calc(var(--margin) * -1);
      background-color: var(--theme-v1-bg, var(--color-green));
      z-index: -1;
    }

    &.banner--alt::before {
      background-color: var(--theme-v2-bg, var(--color-green));
    }
  }

  @include tablet-landscape {
    padding: var(--space-72) 0;
  }

  @include desktop-large {
    &::before {
      left: calc((100vw - var(--max-width)) / 2 * -1);
      right: calc((100vw - var(--max-width)) / 2 * -1);
    }
  }

  .main--rights & {
    grid-template-columns: repeat(10, 1fr);
    padding: var(--space-36) var(--space-24) 0;
    color: var(--color-white);
    background-color: var(--color-blue);
    overflow: hidden;

    &::before {
      content: none;
    }

    @include tablet-portrait {
      padding: var(--space-24) 0;
    }

    @include tablet-landscape {
      padding: var(--space-36) 0;
    }
  }
}

.banner__graphic,
.banner__content {
  grid-column: 1 / -1;
}

.banner__graphic {
  grid-column: 1 / -1;
  position: relative;
  order: 2;
  height: 0;
  margin-top: 2.5rem;
  padding-top: 50%;
  overflow: hidden;

  @include tablet-portrait {
    grid-column: span 5;
    order: 1;
    height: 100%;
    margin-top: 0;
    padding-top: 0;
    overflow: visible;
  }

  @include desktop {
    grid-column: span 3;
  }

  .main--rights & {
    @include tablet-portrait {
      grid-column: span 2;
    }

    @include desktop {
      grid-column: span 2;
    }
  }
}

.banner__graphic__inner {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;

  @include tablet-portrait {
    top: calc(var(--space-56) * -1);
    bottom: calc(var(--space-56) * -1);
    left: calc(var(--margin) * -1);
    right: 0;
    width: unset;
    overflow: hidden;

    svg {
      position: absolute;
      top: 0;
      right: 0;
      width: auto;
      height: calc(100% + 4rem);
      transform: translateY(-2rem);
    }
  }

  @include tablet-landscape {
    top: calc(var(--space-72) * -1);
    bottom: calc(var(--space-72) * -1);

    svg {
      height: calc(100% + 10rem);
      transform: translateY(-5rem);
    }
  }

  @include desktop-large {
    left: calc((100vw - var(--max-width)) / 2 * -1);

    svg {
      height: calc(100% + 15rem);
      transform: translateY(-7.5rem);
    }
  }

  .main--rights & {
    @include tablet-portrait {
      top: calc(var(--space-36) * -1);
      bottom: calc(var(--space-36) * -1);
      right: calc(var(--gutter) * -1);

      svg {
        height: calc(100% + 1rem);
        transform: translateY(-0.5rem);
      }
    }

    @include tablet-landscape {
      svg {
        height: calc(100% + 1.75rem);
        transform: translateY(-0.875rem);
      }
    }

    @include desktop {
      right: 0;

      svg {
        height: calc(100% + 2rem);
        transform: translateY(-1rem);
      }
    }
  }
}

.banner__content {
  grid-column: 1 / -1;
  order: 1;

  @include tablet-portrait {
    grid-column: 7 / span 6;
    order: 2;
  }

  @include desktop {
    grid-column: 5 / span 8;
  }

  .main--rights & {
    @include tablet-portrait {
      grid-column: 3 / span 8;
      margin: 0 var(--gutter);
    }
  }
}

.banner__subheading {
  margin-bottom: 0.25em;
  text-transform: uppercase;

  @include text-15;

  @include tablet-portrait {
    @include text-20;
  }
}

.banner__heading {
  margin-bottom: 1em;
  line-height: var(--lineheight-medium);

  @include text-20;

  @include tablet-portrait {
    @include text-30;
  }

  @include tablet-landscape {
    @include text-40;
  }

  .main--rights & {
    @include text-20;

    @include tablet-landscape {
      @include text-25;
    }
  }
}

.banner__text {
  max-width: 68ch;
  line-height: var(--lineheight-large);
}

.banner__link {
  display: inline-block;
  margin-top: 2em;
}
