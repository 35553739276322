.subjects {
  row-gap: var(--gutter);

  @include subgrid-container;
}

.subjects__item {
  grid-column: 1 / -1;
  position: relative;
  color: var(--color-white);
  box-shadow: 0.4px 0.4px 2.2px rgba(0, 0, 0, 0.028),
    1px 1px 5.3px rgba(0, 0, 0, 0.04), 1.9px 1.9px 10px rgba(0, 0, 0, 0.05),
    3.4px 3.4px 17.9px rgba(0, 0, 0, 0.06),
    6.3px 6.3px 33.4px rgba(0, 0, 0, 0.072), 15px 15px 80px rgba(0, 0, 0, 0.1);
  overflow: hidden;

  @include tablet-portrait {
    grid-column: span 6;
  }

  @include desktop {
    grid-column: span 4;
  }
}

.subjects__item__wrapper {
  display: block;
  padding: var(--space-24);

  &::after {
    content: '-->';
    position: absolute;
    right: var(--space-24);
    bottom: var(--space-24);

    @include text-30;
  }
}

.subjects__item__title {
  margin-bottom: 0.5em;

  @include text-20;
}

.subjects__item__desc {
  margin-bottom: var(--space-56);
  line-height: var(--lineheight-medium);

  @include desktop {
    margin-bottom: var(--space-42);
  }
}

.subjects__item__icon {
  width: 33.33333%;
  height: auto;
  filter: brightness(100);

  @include desktop {
    width: 50%;
  }
}

.subjects__item__background {
  position: absolute;
  top: -6.25%;
  left: -6.25%;
  width: 125%;
  height: 125%;
  object-fit: cover;
  pointer-events: none;
  z-index: -1;
}
