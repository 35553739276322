.navigation,
.navigation__overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.navigation {
  z-index: z('navigation');

  &.is-open {
    pointer-events: auto;
  }

  @include desktop {
    display: none;
  }
}

.navigation__overlay {
  background: var(--color-darkblue);
  opacity: 0;
  z-index: 1;
  transition: opacity 0.4s ease-in-out;

  .is-open & {
    opacity: 0.5;
    pointer-events: auto;
  }
}

.navigation__slideout {
  position: absolute;
  top: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  max-height: 100%;
  padding: 0 var(--margin) var(--margin);
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  background: var(--color-gray);
  box-shadow: -0.9px 0px 0.9px -6px rgba(0, 0, 0, 0.07),
    -2.5px 0px 2.5px -6px rgba(0, 0, 0, 0.1),
    -6px 0px 6px -6px rgba(0, 0, 0, 0.13),
    -20px 0px 20px -6px rgba(0, 0, 0, 0.2);
  transform: translateX(calc(100% + 20px));
  transition: transform 0.4s ease-in-out;
  z-index: 2;

  .is-open & {
    transform: translateX(0);
  }

  @include tablet-portrait {
    width: 50vw;

    > * {
      max-width: 22.625rem;
    }
  }
}

.navigation__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 3.5rem;
  margin-bottom: var(--space-24);

  @include desktop {
    height: unset;
    margin-bottom: var(--space-56);
  }
}

.navigation__logo,
.navigation__close {
  display: flex;
  align-items: center;
}

.navigation__logo {
  width: 6.25rem;
  color: var(--color-darkblue);
}

.navigation__close {
  width: 1.9375rem;
  height: 1.9375rem;
  color: var(--color-red);
  cursor: pointer;
}

.navigation__list {
  margin: auto 0;

  @include text-25;
}

.navigation__sublist {
  margin: 1.25em 0 1.5em 1em;

  @include text-20;
}

.navigation__list__item {
  margin-bottom: 0.75em;
  font-weight: 600;

  .navigation__sublist & {
    margin-bottom: 1em;
  }
}

.navigation__list__link {
  .is-active & {
    border-bottom: 2px solid currentColor;
  }
}
