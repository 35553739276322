.cases__mobile {
  position: relative;
  display: flex;
  flex-direction: column;
  padding-block: var(--space-24);

  @include tablet-portrait {
    display: none;
  }

  .cases__top,
  .cases__bottom {
    margin: 0;
    padding: 0;
    display: flex;
  }

  .cases__top {
    z-index: 2;
  }

  .cases__content {
    margin-block: -1rem -4rem;
    padding: var(--space-36);
    background: var(--color-gold);
    color: var(--color-white);
    z-index: 1;
  }

  .cases__logo {
    position: absolute;
    right: -1px;
    bottom: calc(var(--space-24) - 3px);
    z-index: 3;
    width: 30%;
    height: auto;
  }

  .cases__heading {
    @include font-body;
    @include text-15;

    margin-bottom: var(--space-15);
    text-transform: uppercase;
  }

  .cases__items {
    display: inline-flex;
    width: 100%;
    transition: opacity 0.5s;
    opacity: 1;

    &.is-hidden {
      opacity: 0;
    }
  }

  .cases__item {
    @include font-heading;
    @include text-30;

    font-weight: 300;
    width: 100%;
    flex-shrink: 0;
    text-wrap: balance;
    line-height: var(--lineheight-medium);
    opacity: 0;
    pointer-events: none;
    padding-right: 10%;

    &.is-active {
      opacity: 1;
      pointer-events: auto;
    }
  }

  .cases__indicator {
    position: absolute;
    display: flex;
    justify-content: center;
    margin-top: var(--space-15);
    gap: 0.5rem;
    z-index: 3;
    bottom: calc(var(--space-24) + 2rem);
  }

  .cases__indicator__dot {
    flex: 1 0 auto;
    width: 10px;
    height: 10px;
    border: 1px solid var(--color-white);
    border-radius: 50%;
    cursor: pointer;

    &.is-active {
      background: var(--color-white);
    }
  }
}

.cases__mobile::before,
.cases__desktop::before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: calc(var(--margin) * -1);
  right: calc(var(--margin) * -1);
  background: var(--color-gray);
  z-index: -1;

  @include desktop-large {
    left: calc((100vw - var(--max-width)) / 2 * -1);
    right: calc((100vw - var(--max-width)) / 2 * -1);
  }
}

.cases__desktop {
  display: none;

  @include tablet-portrait {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    padding-block: var(--space-36);

    @include subgrid-container;

    .cases__graphic,
    .cases__indicator {
      grid-column: 2 / span 2;
    }

    .cases__indicator {
      display: flex;
      justify-content: center;
      gap: 0.5rem;
      margin-top: 1.5rem;
    }

    .cases__indicator__dot {
      flex: 0 0 auto;
      width: 10px;
      height: 10px;
      border: 1px solid var(--color-gold);
      border-radius: 50%;
      cursor: pointer;

      &.is-active {
        background: var(--color-gold);
      }
    }

    .cases__content {
      grid-column: 5 / span 7;
      grid-row: 1 / span 2;
    }

    .cases__items {
      display: inline-flex;
      width: 100%;
      transition: opacity 0.5s;
      opacity: 1;

      &.is-hidden {
        opacity: 0;
      }
    }

    .cases__item {
      @include text-15;

      width: 100%;
      flex-shrink: 0;
      text-wrap: balance;
      line-height: var(--lineheight-large);
      opacity: 0;
      color: var(--color-gold);
      pointer-events: none;

      span {
        text-transform: uppercase;
      }

      &.is-active {
        opacity: 1;
        pointer-events: auto;
      }
    }
  }
}
