.form {
  position: relative;
  color: var(--theme-v1-fg, var(--color-black));

  @include subgrid-container;

  &::before {
    content: '';
    position: absolute;
    top: var(--space-36);
    bottom: 0;
    left: calc(var(--margin) * -1);
    right: calc(var(--margin) * -1);
    background: var(--theme-v1-bg, var(--color-gray));
    z-index: -1;

    @include tablet-portrait {
      top: 0;
    }

    @include desktop-large {
      left: calc((100vw - var(--max-width)) / 2 * -1);
      right: calc((100vw - var(--max-width)) / 2 * -1);
    }
  }

  button[type='submit'] {
    background: var(--theme-v1-bg, var(--color-blue));
    color: var(--theme-v1-fg, var(--color-white));
    border: 1px var(--theme-v1-fg, var(--color-blue)) solid;
  }

  .fui-label {
    color: var(--theme-v1-fg, var(--color-black));
  }

  .fui-alert {
    padding: 0;
    background: none;
    line-height: var(--lineheight-medium);

    @include text-30;
  }

  .fui-alert-success {
    color: var(--theme-v1-fg, var(--color-green));
  }

  .fui-alert-error {
    color: var(--theme-v1-fg, var(--color-red));
  }

  .fui-i {
    color: currentColor;
  }
}

.form--alt {
  color: var(--theme-v2-fg, var(--color-black));

  button[type='submit'] {
    background: var(--theme-v2-bg, var(--color-blue));
    color: var(--theme-v2-fg, var(--color-white));
    border: 1px var(--theme-v2-fg, var(--color-blue)) solid;
  }

  .fui-alert-success {
    color: var(--theme-v2-fg, var(--color-green));
  }

  .fui-alert-error {
    color: var(--theme-v2-fg, var(--color-red));
  }

  &::before {
    background: var(--theme-v2-bg, var(--color-gray));
  }
}

.form__media,
.form__content {
  grid-column: 1 / -1;
}

.form__media {
  position: relative;
  margin-bottom: var(--space-24);

  @include tablet-portrait {
    grid-column: span 6;
    margin-bottom: 0;
  }

  @include tablet-landscape {
    grid-column: span 5;
  }
}

.form__media__wrapper {
  @include tablet-portrait {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: calc(var(--margin) * -1);

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  @include desktop-large {
    left: calc((100vw - var(--max-width)) / 2 * -1);
  }
}

.form__content {
  margin-bottom: var(--space-36);

  @include tablet-portrait {
    grid-column: span 6;
    margin: var(--space-42) 0;
  }

  @include tablet-landscape {
    grid-column: span 7;
    display: grid;
    grid-template-columns: 1fr 5fr 1fr;
    column-gap: var(--gutter);
    margin: var(--space-56) 0;

    > * {
      grid-column: 2 / span 1;
    }
  }
}

.form__heading {
  margin-bottom: 2.5em;

  @include text-20;
}

.form__text {
  margin-top: var(--space-42);
  line-height: var(--lineheight-large);
}
