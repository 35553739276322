.newsletter {
  position: relative;
  width: var(--full-width);
  padding: var(--space-36) 0;
  background: var(--color-gray);

  &::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: -100vw;
    right: -100vw;
    background: var(--color-gray);
    z-index: -1;
  }
}

.newsletter__content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  @include tablet-landscape {
    flex-direction: row;
    align-items: center;
  }
}

.newsletter__heading {
  margin-bottom: 1em;

  @include text-20;
}

.newsletter__desc {
  max-width: 64ch;
  margin-bottom: 2em;
  line-height: var(--lineheight-large);

  @include tablet-landscape {
    margin-bottom: 0;
  }
}

.newsletter__link {
  flex-shrink: 0;
}
