.content--front {
  @include grid-container;

  .content__block {
    width: 100%;
    grid-column: 1 / -1;

    &:not(:first-child) {
      margin-top: var(--space-42);
    }

    @include tablet-portrait {
      &:not(:first-child) {
        margin-top: var(--space-56);
      }
    }

    @include tablet-landscape {
      &.content__block--half {
        grid-column: span 5;
      }

      &:not(:first-child) {
        margin-top: var(--space-72);
      }
    }

    @include desktop {
      &:not(:first-child) {
        margin-top: var(--space-100);
      }
    }
  }

  .content__block--half {
    @include tablet-portrait {
      grid-column: span 6;
    }
  }

  .content__block--full.content__block--text,
  .content__block--accordion,
  .content__block--image,
  .content__block--video,
  .content__block--columns,
  .content__block--carousel,
  .content__block--quote {
    @include tablet-portrait {
      grid-column: 2 / -2;
    }
  }

  .content__block--text-entry + .content__block--text-entry {
    @include tablet-portrait {
      grid-column: 7 / span 6;
    }
  }

  .content__block--campaigns:first-child {
    margin-top: calc(var(--space-42) * -1);

    @include tablet-portrait {
      margin-top: calc(var(--space-56) * -1);
    }

    @include tablet-landscape {
      margin-top: calc(var(--space-72) * -1);
    }

    @include desktop {
      margin-top: calc(var(--space-100) * -1);
    }
  }

  .content__block--columns {
    border-top: 1px solid var(--color-darkgray);
  }

  .content__block--accordion + .content__block--accordion,
  .content__block--columns + .content__block--columns,
  .content__block--columns + .content__block--accordion,
  .content__block--accordion + .content__block--columns {
    margin-top: 0;
    border-top: none;
  }

  .content__block--home {
    @include tablet-portrait {
      display: none;
    }
  }
}
